import { Link } from "gatsby";

import tw from "tailwind-styled-components";

export const Article = tw.article`
  w-full
  pt-32 md:pt-32
  px-12 md:px-24
  text-center

  bg-cover
  bg-repeat
  bg-welcome-img
`;

export const Title = tw.h1`
  text-white
  font-baloo 
  text-4xl md:text-6xl
`;

export const Description = tw.p`
  text-white
  font-comfortaa 
  text-xl md:text-2xl
`;

export const ButtonCTA = tw(Link)`
  outline-none
  no-underline 
  text-blue-pensamental-dark 
  inline-flex 
  items-center 
  justify-center 
  px-12 py-3
  font-baloo 
  rounded-md 
  bg-blue-pensamental-light 
  cursor-pointer
  transition duration-300 ease-in-out 
  transform 
  hover:bg-blue-pensamental-light
  hover:scale-110
  hover:text-white
  focus:ring-2 focus:ring-blue-pensamental-dark
`;

export const ImageDevices = tw.img`
  w-full
  max-w-screen-xl	
  inline-flex 
  items-center 
  justify-center 
  border-none
  bg-cover
  bg-repeat
`;
