/* eslint-disable node/no-unsupported-features/es-syntax */
/* eslint-disable import/first */
import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import useInView from "react-cool-inview";
import Tracker from "@openreplay/tracker/cjs";

// configs
import Main from "../components/main";
import Header from "../components/header";
import Seo from "../components/seo";

// sections
import Section from "../components/section";
import Welcome from "../components/welcome";
import CookieConsentShared from "../components/_shared_/consent/CookieConsent";
import ButtonCTA, { Area } from "../components/buttonCTA";

const Features = React.lazy(() => import("../components/features"));
const Skills = React.lazy(() => import("../components/skills"));
const FirstReason = React.lazy(() => import("../components/reasons/first"));
const SecondReason = React.lazy(() => import("../components/reasons/second"));
const Subjects = React.lazy(() => import("../components/subjects"));
const Depositions = React.lazy(() => import("../components/depositions"));
const Plans = React.lazy(() => import("../components/plans"));
const FAQs = React.lazy(() => import("../components/faq"));
const Footer = React.lazy(() => import("../components/footer"));

// open replay
const tracker = new Tracker({ projectKey: "mJk2kHRhU2x0UP887beO", __DISABLE_SECURE_MODE: true });

// imgs
import texturaLeft from "../images/textura-left.svg";
import texturaRight from "../images/textura-right.svg";

// styles
import "../styles/index.css";

function Home() {
  const isSSR = typeof window === "undefined";

  const { observe: observeFeature, inView: inViewFeature } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeSkill, inView: inViewSkill } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeFirst, inView: inViewFirst } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeSecond, inView: inViewSecond } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeSubject, inView: inViewSubject } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeCTA, inView: inViewCTA } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeDepositions, inView: inViewDepositions } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observePlans, inView: inViewPlans } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeFAQ, inView: inViewFAQ } = useInView({ onEnter: ({ unobserve }) => unobserve() });
  const { observe: observeFooter, inView: inViewFooter } = useInView({ onEnter: ({ unobserve }) => unobserve() });

  useEffect(() => {
    window?.FB?.CustomerChat?.hideDialog();
  }, []);

  useEffect(() => {
    if (!isSSR) {
      tracker.start();
    }
  }, []);

  if (!isSSR) {
    return (
      <>
        <Seo title="- O melhor da saúde mental" />
        <CookieConsentShared />
        <Main className="z-30">
          <Header />
          <Section>
            <Welcome />

            <img alt="textura-right" className="absolute right-0 opacity-20" src={texturaRight} />

            <ArticleFeature ref={observeFeature}>
              {inViewFeature && (
                <React.Suspense fallback={<div />}>
                  <Features />
                </React.Suspense>
              )}
            </ArticleFeature>

            <ArticleSkill ref={observeSkill}>
              {inViewSkill && (
                <React.Suspense fallback={<div />}>
                  <Skills />
                </React.Suspense>
              )}
            </ArticleSkill>

            <img alt="textura-left" className="absolute opacity-20" src={texturaLeft} />

            <ArticleReason ref={observeFirst}>
              {inViewFirst && (
                <React.Suspense fallback={<div />}>
                  <FirstReason />
                </React.Suspense>
              )}
            </ArticleReason>

            <ArticleReason ref={observeSecond}>
              {inViewSecond && (
                <React.Suspense fallback={<div />}>
                  <SecondReason />
                </React.Suspense>
              )}
            </ArticleReason>

            <ArticleGeneric ref={observeSubject}>
              {inViewSubject && (
                <React.Suspense fallback={<div />}>
                  <Subjects />
                </React.Suspense>
              )}
            </ArticleGeneric>

            <Area ref={observeCTA}>
              {inViewCTA && (
                <ButtonCTA rel="noreferrer" target="_blank" href="https://payment.hotmart.com/D56468073W?off=arzl50v4&checkoutMode=10">
                  TESTE GRÁTIS POR 7 DIAS
                </ButtonCTA>
              )}
            </Area>

            <img alt="textura-right" className="absolute right-0 opacity-20" src={texturaRight} />

            <ArticleGeneric ref={observeDepositions}>
              {inViewDepositions && (
                <React.Suspense fallback={<div />}>
                  <Depositions />
                </React.Suspense>
              )}
            </ArticleGeneric>

            <img alt="textura-left" className="absolute opacity-20" src={texturaLeft} />

            <ArticleGeneric ref={observePlans}>
              {inViewPlans && (
                <React.Suspense fallback={<div />}>
                  <Plans />
                </React.Suspense>
              )}
            </ArticleGeneric>

            <ArticleGeneric ref={observeFAQ}>
              {inViewFAQ && (
                <React.Suspense fallback={<div />}>
                  <FAQs />
                </React.Suspense>
              )}
            </ArticleGeneric>
          </Section>

          <FooterSC $isDarkest={false} ref={observeFooter}>
            {inViewFooter && (
              <React.Suspense fallback={<div />}>
                <Footer />
              </React.Suspense>
            )}
          </FooterSC>
        </Main>
      </>
    );
  }

  return null;
}

export default Home;

export const ArticleSkill = tw.article`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  bg-pensamental-dark
  px-10
  py-10 md:py-12
`;

export const ArticleFeature = tw.article`
  w-full
  flex
  justify-center
  items-center
  bg-pensamental-dark
  py-12
  px-4
`;

export const ArticleReason = tw.article`
  w-full
  flex 
  justify-center
  bg-pensamental-dark
  py-10
`;

export const ArticleGeneric = tw.article`
  w-full
  flex 
  flex-col
  justify-center
  items-center
  bg-pensamental-dark
  px-10
  pt-8 md:pt-10
  pb-10 md:pb-12
`;

export const FooterSC = tw.footer`
  flex
  items-center 
  justify-center 
  w-full 
  pb-8 

  ${(props) => (props.$isDarkest ? "bg-blue-pensamental-darkest" : "bg-blue-pensamental-dark")}
`;
