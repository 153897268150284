import React, { useEffect } from "react";
import { Link } from "gatsby";

import CookieConsent from "react-cookie-consent";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function CookieConsentShared() {
  const handleAccept = () => {
    setTimeout(() => {
      window?.FB.XFBML.parse();
    }, [5000]);

    setTimeout(() => {
      window?.FB?.CustomerChat?.show();
    }, [10000]);
  };

  useEffect(() => {
    if (getCookie("CookieConsent") === "true") {
      handleAccept();
    }
  }, []);

  return (
    <CookieConsent
      location="bottom"
      onAccept={handleAccept}
      buttonText="ACEITAR"
      expires={150}
      style={{
        color: "#fff",
        fontSize: "13px",
        fontFamily: "Comfortaa",
        background: "rgb(4 27 59)",
        alignItems: "center ",
        zIndex: "2147483647",
        justfyCenter: "center",
      }}
      contentStyle={{
        padding: "10px",
      }}
      buttonStyle={{
        color: "#fff",
        backgroundColor: "#5FB3E2",
        fontSize: "13px",
        fontFamily: "Baloo",
        padding: "12px 24px",
        borderRadius: "6px",
      }}
    >
      Utilizamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços, personalizar publicidade e recomendar conteúdo de seu interesse em nossa plataforma e em
      serviços de terceiros. Ao navegar pelo site, você autoriza a empresa Pensamental a coletar estes dados e utilizá-los para estes fins. Consulte nossa{" "}
      <Link className="text-blue-pensamental-light hover:underline" to="/privacidade">
        Política de Privacidade e Proteção de Dados{" "}
      </Link>{" "}
      para mais detalhes.
    </CookieConsent>
  );
}

export default CookieConsentShared;
